<template>
  <Page
    :title="$t('help.title')"
    @update:currentTab="currentTab = $event"
    :currentTab="currentTab"
    :tabs="tabs"
    :classes="['page--static', 'page--help']"
  >
      <component :is="currentTab.component"></component>
  </Page>
</template>

<script>
import ChallengesHelpComponent from '@/components/help/components/ChallengesHelpComponent.vue';
import FaqsHelpComponent from '@/components/help/components/FaqsHelpComponent.vue';

export default {
  name: 'HelpPage',
  components: {
    ChallengesHelpComponent,
    FaqsHelpComponent,
  },
  data() {
    return {
      currentTab: { name: this.$i18n.t('common.challenges'), component: 'ChallengesHelpComponent' },
      tabs: [
        { name: this.$i18n.t('common.challenges'), component: 'ChallengesHelpComponent' },
        { name: this.$i18n.t('common.faqs'), component: 'FaqsHelpComponent' },
      ],
    };
  },
};
</script>

<style lang="scss">
</style>
