<template>
  <a :class="isRequired ? 'essential' : 'complementary'">{{tagName()}}</a>
</template>

<script>
export default {
  name: 'ChallengeTypeTag',
  props: {
    isRequired: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  methods: {
    tagName() {
      return this.isRequired ? this.$t('tags.essential') : this.$t('tags.complementary'); 
    }
  }

  }
</script>

<style lang="scss" scoped>
  a {
    -webkit-text-size-adjust: 100%;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji";
    word-wrap: break-word;
    list-style-type: none;
    box-sizing: border-box;
    text-decoration: none;
    display: inline-block;
    padding: 0 7px;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    border: 1px solid transparent;
    border-radius: 2em;
    --perceived-lightness: calc( ((var(--label-r) * 0.2126) + (var(--label-g) * 0.7152) + (var(--label-b) * 0.0722)) / 255 );
    --lightness-switch: max(0, min(calc((var(--perceived-lightness) - var(--lightness-threshold)) * -1000), 1));
    --border-threshold: 0.96;
    --lightness-threshold: 0.6;
    --background-alpha: 0.18;
    --border-alpha: 0.3;
    --lighten-by: calc(((var(--lightness-threshold) - var(--perceived-lightness)) * 100) * var(--lightness-switch));

    &.essential {
      background: rgba(var(--label-r), var(--label-g), var(--label-b), var(--background-alpha));
      color: hsl(var(--label-h), calc(var(--label-s) * 1%), calc((var(--label-l) + var(--lighten-by)) * 1%));
      border-color: hsla(var(--label-h), calc(var(--label-s) * 1%), calc((var(--label-l) + var(--lighten-by)) * 1%), var(--border-alpha));
      --label-r: 109;
      --label-g: 232;
      --label-b: 121;
      --label-h: 125;
      --label-s: 72;
      --label-l: 66;
      }
    &.complementary {
      background: rgba(var(--label-r), var(--label-g), var(--label-b), var(--background-alpha));
      color: hsl(var(--label-h), calc(var(--label-s) * 1%), calc((var(--label-l) + var(--lighten-by)) * 1%));
      border-color: hsla(var(--label-h), calc(var(--label-s) * 1%), calc((var(--label-l) + var(--lighten-by)) * 1%), var(--border-alpha));
      --label-r: 254;
      --label-g: 204;
      --label-b: 89;
      --label-h: 41;
      --label-s: 98;
      --label-l: 67;
    }
  }
</style>
