<template>
<div>

    <div v-for="(n,i) in 10" :key="i" class="help__challenge">

      <div class="help__challenge-title" @click="toggleQuestion(n)">
        <h4>{{$t(`faqs.${n}.question`)}}</h4>
        <span>
          <img
            :class="{'is-active': questionIsExpanded(n)}"
            src="/img/icons/report/arrow.svg" alt="Show more">
        </span>
      </div>

      <div
        class="help__entitytype"
        v-show="questionIsExpanded(n)">
        <div class="help__entitytype-wrap">
          <div class="help__entitytype-content">
            <div
              v-html="$t(`faqs.${n}.answer`)">
            </div>
          </div>
        </div>
      </div>

    </div>
</div>
</template>

<script>
export default {
  name: 'FaqsHelpComponent',
  data() {
    return {
      expandedQuestions: [],
    };
  },
  methods: {
    toggleQuestion(n) {
      if (this.questionIsExpanded(n)) {
        this.expandedQuestions.splice(this.expandedQuestions.indexOf(n), 1);
      } else {
        this.expandedQuestions.push(n);
      }
    },
    questionIsExpanded(n) {
      return this.expandedQuestions.indexOf(n) > -1;
    },
  },
};
</script>

<style lang="scss" scoped>
.help {
  &__intro {
    margin-bottom: 60px;
  }
  &__challenge {
    margin-top: 30px;
    padding-top: 30px;
    border-top: 1px dashed var(--border-color-default);
  }
  &__challenge-title {
    cursor: pointer;
    display: flex;
    h4 {
      flex: 0 0 calc(100% - 24px);
      font-size: 18px;
      margin-bottom: 0;
      padding-right: 20px;
      box-sizing: border-box;
    }
    span {
      display: block;
      flex: 0 0 24px;
    }
    img {
      transition: transform 200ms ease;
      transform: rotate(-90deg);
      &.is-active {
        transform: rotate(90deg);
      }
    }
  }
  &__entitytype {
    margin-top: 20px;
    + .help__entitytype {
      margin-top: 30px;
      padding-top: 30px;
      border-top: 1px solid var(--border-color-default);
    }
  }
  &__entitytype-wrap {
    display: flex;
  }
  &__entitytype-title {
    flex: 0 0 100px;
    font-weight: 700;
    color: var(--primary-color-strong);
  }
}

@media screen and (min-width: 768px) {
  .help {
    &__challenge-title {
      h4 {
        font-size: 20px;
      }
    }
    &__entitytype-title {
      flex: 0 0 180px;
    }
  }
}
</style>
