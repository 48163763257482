<template>
<div>
    <div class="help__intro">{{$t('help.intro')}}</div>

    <div v-for="(challenge, i) in challenges" :key="i" class="help__challenge">

      <div class="help__challenge-title" @click="toggleChallenge(challenge)">
        <h4><span>{{$t(`challenges.${challenge.name}`)}}</span><ChallengeTypeTag :isRequired="challenge.is_required" /></h4>
        <span>
          <img
            :class="{'is-active': challengeIsExpanded(challenge)}"
            src="/img/icons/report/arrow.svg" alt="Show more">
        </span>
      </div>

      <div
        v-for="(type, j) in entityTypes"
        :key="j"
        class="help__entitytype"
        v-show="challengeIsExpanded(challenge) && $t(`challenges_short.${type}.${challenge.name}`) !== ''">
        <div
          class="help__entitytype-wrap"
          v-if="$t(`challenges_short.${type}.${challenge.name}`) !== ''">
          <div class="help__entitytype-title">
            <span>{{$t(`entityType.${type}`)}}</span>
          </div>
          <div class="help__entitytype-content">
            <div
              v-html="$t(`challenges_short.${type}.${challenge.name}`)"></div>
            <div
              v-html="$t(`challenges_long.${type}.${challenge.name}`)"
              v-if="$t(`challenges_long.${type}.${challenge.name}`) !== ''"></div>
          </div>
        </div>
      </div>

    </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex';
import ChallengeTypeTag from './ChallengeTypeTag.vue';

export default {
    name: 'ChallengesHelpComponent',
    data() {
        return {
            expandedChallenges: [],
        };
    },
    computed: {
        ...mapGetters(['challenges', 'entityTypes']),
    },
    methods: {
        toggleChallenge({ name }) {
            if (this.challengeIsExpanded({ name })) {
                this.expandedChallenges.splice(this.expandedChallenges.indexOf(name), 1);
            } else {
                this.expandedChallenges.push(name);
            }
        },
        challengeIsExpanded({ name }) {
            return this.expandedChallenges.indexOf(name) > -1;
        },
    },
    components: { ChallengeTypeTag }
};
</script>

<style lang="scss" scoped>
.help {
  &__intro {
    margin-bottom: 60px;
  }
  &__challenge {
    margin-top: 30px;
    padding-top: 30px;
    border-top: 1px dashed var(--border-color-default);
  }
  &__challenge-title {
    cursor: pointer;
    display: flex;
    h4 {
      flex: 0 0 calc(100% - 24px);
      font-size: 18px;
      margin-bottom: 0;
      padding-right: 20px;
      box-sizing: border-box;
      span {
        display: inline-block;
        margin-right: 20px;
      }
    }
    span {
      display: block;
      flex: 0 0 24px;
    }
    img {
      transition: transform 200ms ease;
      transform: rotate(-90deg);
      &.is-active {
        transform: rotate(90deg);
      }
    }
  }
  &__entitytype {
    margin-top: 20px;
    + .help__entitytype {
      margin-top: 30px;
      padding-top: 30px;
      border-top: 1px solid var(--border-color-default);
    }
  }
  &__entitytype-wrap {
    display: flex;
  }
  &__entitytype-title {
    flex: 0 0 100px;
    font-weight: 700;
    color: var(--primary-color-default);
  }
}

@media screen and (min-width: 768px) {
  .help {
    &__challenge-title {
      h4 {
        font-size: 20px;
      }
    }
    &__entitytype-title {
      flex: 0 0 180px;
    }
  }
}
</style>
